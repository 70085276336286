const strings = {
  wrong: "Wrong",
  correct: "Correct",
  welcome: "Welcome to VKU",
  submit: "Submit",
  registered: "Registered!",
  thanksForRegistering: "Thank you for registering.",
  step1: "Step 1",
  step2: "Step 2",
  someMaterials:
    "Some materials in the booklet are in 360 video format. Please download the Youtube app.",
  simplyScan:
    "Simply scan the QR codes in your booklet to access the interactive materials.",
  emailAddress: "E-mail address",
  enterEmailAddress: "Please enter your e-mail address to continue",
  previousQuestion: "Previous question",
  nextQuestion: "Next",
  interactiveQuiz: "Interactive Quiz",
  interactiveInfo:
    "In this quiz, you will watch a short hazard video. <strong>Tap when you see a hazard developing.</strong> Are you ready?",
  viewInYoutube: "View in Youtube app",
  check: "Check",
  tapToStart: "Tap To Start",
  tapAnywhereWhenHazard: "Tap anywhere when hazard is developing",
  exit: "EXIT",
  tryAgain: "Try again",
  seeSolution: "See Solution",
  finish: "Finish",
  result: "Result",
  return: "Return"
};

export default strings;
