import { createContext } from "react";
import { LocaleType } from "./de";

type LanguageContextType = {
  language: string;
  strings?: LocaleType;
  setLanguage: (language: string) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  language: "de",
  setLanguage: (_: string) => {}
});
