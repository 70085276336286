import {
  faCaretDown,
  faInfoCircle,
  faPlayCircle,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "@reach/router";
import React, {
  CSSProperties,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { CenteredLayout, CenteredWhiteContainer } from "./layout";
import Loading from "./loading";
import { ProblemType, UnitType } from "./quiz";
import { WhoAmIContainer } from "../App";
import { LanguageContext } from "../locale";

// const sampleVideo = () => {
//   if (process.env.NODE_ENV === "production") {
//     return null;
//   }
//   return require("../assets/samples/hp_01.mp4");
// };

interface InteractiveViewProps {
  videoURL: string;
  userTime: Array<{
    time: number;
    score: number;
  }>;
  solutions: ProblemType["answers"];
  onTap: () => void;
  isSolutionShown: boolean;
  onClickSolution: () => void;
  onClickExit: () => void;
  videoRef: RefObject<HTMLVideoElement>;
  currentTime: number;
  duration: number;
  isPlaying: boolean;
  isEnded: boolean;
  answers?: Array<{
    start: number;
    end: number;
    score: number;
  }>;
}
const playtimeStyle: CSSProperties = {
  position: "absolute",
  bottom: 20,
  left: 20,
  right: 20,
  height: 10,
  backgroundColor: "rgba(255,255,255,0.5)",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  justifyContent: "flex-start"
};
const InteractiveView = ({
  userTime,
  solutions,
  onTap,
  videoRef,
  currentTime,
  duration,
  isPlaying,
  isEnded,
  isSolutionShown,
  onClickSolution,
  onClickExit,
  videoURL
}: InteractiveViewProps) => {
  const { strings } = useContext(LanguageContext);
  return (
    <div className="relative w-full h-screen">
      <div
        style={{
          position: "absolute",
          transform: "rotate(90deg)",
          transformOrigin: "bottom left",
          width: "calc(100vh - 100px)",
          height: "100vw",
          marginTop: "-100vw",
          maxWidth: "inherit",
          visibility: "visible"
        }}>
        <video
          ref={videoRef}
          id="myVideo"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            maxWidth: "inherit",
            zIndex: 4,
            visibility: "visible",
            background: "black"
          }}
          playsInline={true}>
          <source src={videoURL} type="video/mp4" />
        </video>
        <div style={playtimeStyle}>
          <div
            className="bg-white"
            style={{ flex: (currentTime || 0) / (duration || 1) }}></div>
        </div>
        {isSolutionShown &&
          solutions &&
          solutions.length > 0 &&
          solutions.map((t, i) => {
            const flex = parseFloat(t.start || "") / (duration || 1);
            const markerFlex =
              (parseFloat(t.finish || "") - parseFloat(t.start || "")) /
              (duration || 1);
            const shades = [
              "bg-green-300",
              "bg-green-400",
              "bg-green-500",
              "bg-green-600",
              "bg-green-700"
            ];
            return (
              <div
                key={i}
                style={{
                  ...playtimeStyle,
                  background: "transparent"
                }}>
                <div
                  style={{
                    flex
                  }}></div>
                <div
                  className={` ${shades[(t?.score ?? 0) - 1]}`}
                  style={{
                    flex: markerFlex
                  }}></div>
              </div>
            );
          })}
        {userTime &&
          userTime.map((ut, i) => {
            return (
              <div
                key={i}
                style={{
                  ...playtimeStyle,
                  bottom: 30,
                  background: "transparent"
                }}>
                <div
                  style={{
                    position: "relative",
                    flex: (ut.time || 0) / (duration || 1)
                  }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className="text-white  absolute right-0 bottom-0 w-10 text-lg"
                    style={{ marginRight: -5 }}
                  />
                </div>
              </div>
            );
          })}
        {!isPlaying && (
          <div
            className="absolute top-0 left-0 right-0 bottom-0  flex flex-col items-center justify-center text-white text-3xl z-20"
            onClick={() => onTap()}>
            <FontAwesomeIcon icon={faPlayCircle} />
            <p className="text-white">{strings?.tapToStart}</p>
          </div>
        )}
        {!isPlaying && (
          <button
            onClick={onClickExit}
            className=" z-50 text-white absolute top-0 right-0 mx-5 my-5 text-sm">
            {strings?.exit}
          </button>
        )}
        {isPlaying && (
          <div className="absolute top-0 right-0 flex flex-row justify-end items-center">
            <p className="mt-2 mx-2 opacity-50 px-4 py-2 bg-white rounded-lg">
              <FontAwesomeIcon icon={faInfoCircle} />{" "}
              {strings?.tapAnywhereWhenHazard}
            </p>
            <button onClick={onClickExit} className=" z-50 text-white mx-3">
              {strings?.exit}
            </button>
          </div>
        )}
        <div
          className="absolute top-0 left-0 right-0 bottom-0 flex flex-row z-10"
          onClick={(e) => {
            onTap();
          }}></div>
        {isEnded && !isSolutionShown && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col z-30 items-center justify-center">
            <div className="bg-green-500 text-white rounded  my-10 flex flex-col items-center py-10 px-20">
              <div className="mb-2">
                {starsForUserScore(userTime?.map((u) => u.score || 0))}
              </div>
              <div className="flex flex-row justify-around space-x-5">
                <button className="underline" onClick={() => onTap()}>
                  {strings?.tryAgain}
                </button>
                <button className="underline" onClick={() => onClickSolution()}>
                  {strings?.seeSolution}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const starsForUserScore = (userScores: Array<number>): JSX.Element => {
  const highScore = Math.max(...userScores);
  var stars = [0, 1, 2, 3, 4].map((i) => {
    return (
      <FontAwesomeIcon key={i} icon={i < highScore ? faStar : faStarRegular} />
    );
  });

  return <div className="flex flex-row space-x-2">{stars}</div>;
};

interface InteractiveVideoViewProps {
  onClickExit: () => void;
  onDidEnd: () => void;
  data: ProblemType;
}

// const sampleData = () => {
//   if (process.env.NODE_ENV === "production") {
//     return null;
//   }

//   return {
//     videoURL: sampleVideo(),
//     solutions: [
//       {
//         start: 40,
//         end: 41,
//         score: 5
//       },
//       {
//         start: 42,
//         end: 43,
//         score: 4
//       },
//       {
//         start: 44,
//         end: 45,
//         score: 3
//       },
//       {
//         start: 46,
//         end: 47,
//         score: 2
//       },
//       {
//         start: 48,
//         end: 49,
//         score: 1
//       }
//     ]
//   };
// };
const InteractiveVideoView = ({
  onClickExit,
  onDidEnd,
  data
}: InteractiveVideoViewProps) => {
  const [userTime, setUserTime] = useState<
    Array<{
      time: number;
      score: number;
    }>
  >([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSolutionShown, setIsSolutionShown] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  function playingListener() {
    setUserTime([]);
    setIsEnded(false);
    setIsPlaying(true);
    setIsSolutionShown(false);
    console.log("video playing");
  }
  function endedListener() {
    setIsPlaying(false);
    setIsEnded(true);
    console.log("video ended");

    // if (onDidEnd) {
    //   onDidEnd();
    // }
  }
  function timeUpdateListener() {
    setCurrentTime(videoRef.current?.currentTime || 0);
  }
  function pauseListener() {
    setIsPlaying(false);
    console.log("video paused");
  }

  function scoreForUserTapTime(time: number): number {
    const matchedScore = data?.answers?.filter(
      (sc) =>
        parseFloat(sc.start || "") <= time && parseFloat(sc.finish || "") > time
    );
    if (matchedScore && matchedScore.length > 0) {
      return matchedScore[0].score ?? 0;
    }

    return 0;
  }

  useEffect(() => {
    const videoEl = videoRef.current;
    videoEl?.addEventListener("playing", playingListener);
    videoEl?.addEventListener("pause", pauseListener);
    videoEl?.addEventListener("ended", endedListener);
    videoEl?.addEventListener("timeupdate", timeUpdateListener);

    return () => {
      videoEl?.removeEventListener("playing", playingListener);
      videoEl?.removeEventListener("pause", pauseListener);
      videoEl?.removeEventListener("ended", endedListener);
      videoEl?.removeEventListener("timeupdate", timeUpdateListener);
    };
  }, [videoRef]);

  return (
    <InteractiveView
      videoURL={
        data?.videoURL
          ? `${
              process.env.REACT_APP_MEDIA_URL ?? process.env.PUBLIC_URL
            }/uploads/${data?.videoURL || ""}`
          : ""
      }
      onClickExit={onClickExit}
      solutions={data?.answers || []}
      isSolutionShown={isSolutionShown}
      onClickSolution={() => setIsSolutionShown(true)}
      isEnded={isEnded}
      isPlaying={isPlaying}
      duration={videoRef.current?.duration || 0}
      currentTime={currentTime}
      userTime={userTime}
      videoRef={videoRef}
      onTap={() => {
        if (!isPlaying) {
          videoRef.current?.play();
        } else {
          const tap = videoRef.current?.currentTime;
          if (tap) {
            setUserTime((userTime) => {
              return [
                ...userTime,
                {
                  time: tap,
                  score: scoreForUserTapTime(tap)
                }
              ];
            });
          }
        }
      }}
    />
  );
};

const InteractivePage = (props: RouteComponentProps) => {
  const [isLoading] = useState(false);
  const [showInteractive, setShowInteractive] = useState(false);
  const data = (props.location?.state as any).unit as UnitType;
  const { strings } = useContext(LanguageContext);

  if (isLoading) {
    return (
      <CenteredLayout>
        <div className="text-white">
          <Loading />
        </div>
      </CenteredLayout>
    );
  }

  if (showInteractive) {
    return (
      <InteractiveVideoView
        data={data.problems?.[0]!}
        onClickExit={() => setShowInteractive(false)}
        onDidEnd={() => {}}
      />
    );
  }

  return (
    <CenteredLayout>
      <CenteredWhiteContainer>
        <h1 className="text-2xl font-extrabold mb-4 break-word">
          {strings?.interactiveQuiz}
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: `<p class="break-words">${strings?.interactiveInfo}</p>`
          }}
        />

        <button
          onClick={(e) => {
            setShowInteractive(true);
          }}
          className="btn-blue mt-6">
          START
        </button>
      </CenteredWhiteContainer>
    </CenteredLayout>
  );
};

const InteractivePageWithToken = (props: RouteComponentProps) => {
  return (
    <WhoAmIContainer>
      <InteractivePage {...props} />
    </WhoAmIContainer>
  );
};

export default InteractivePageWithToken;
