import {
  faCheckCircle,
  faChevronRight,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, RouteComponentProps } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import { get } from "../api";
import { WhoAmIContainer } from "../App";
import { LanguageContext } from "../locale";
import { isDemo } from "../utils/is-demo";

interface ResultPageProps extends RouteComponentProps {
  unitId?: string;
}
type ProblemType = {
  userIsCorrect: boolean;
  id: string;
};
const ResultPage = (props: ResultPageProps) => {
  const unitId = props.unitId;
  const unitFromState = (props.location?.state as any).unit;
  const demo = isDemo();
  const { strings } = useContext(LanguageContext);
  const [result, setResult] = useState<{ correct: number; false: number }>();
  const [problems, setProblem] = useState<Array<ProblemType>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (unitFromState) {
      setProblem(
        // @ts-ignore
        unitFromState.problems.map((p) => ({
          id: p.id,
          userIsCorrect: p.userIsCorrect
        }))
      );

      const correctResult = unitFromState.problems.reduce(
        // @ts-ignore
        (prev, curr) => {
          return {
            correct: prev.correct + (curr.userIsCorrect ? 1 : 0),
            false: prev.false + (!curr.userIsCorrect ? 1 : 0)
          };
        },
        { correct: 0, false: 0 }
      );
      setResult(correctResult);
      setIsLoading(false);

      return;
    }
    setIsLoading(true);
    get(`/unit/${unitId}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          console.log(res.data);
          setProblem(
            // @ts-ignore
            res.data.problems.map((p) => ({
              id: p.id,
              userIsCorrect: p.userIsCorrect
            }))
          );

          const correctResult = res.data.problems.reduce(
            // @ts-ignore
            (prev, curr) => {
              return {
                correct: prev.correct + (curr.userIsCorrect ? 1 : 0),
                false: prev.false + (!curr.userIsCorrect ? 1 : 0)
              };
            },
            { correct: 0, false: 0 }
          );
          console.log(correctResult);
          setResult(correctResult);
        } else {
          console.log(res);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [unitId, unitFromState]);

  if (isLoading) {
    return null;
  }

  if (!result) {
    return null;
  }

  const percentage = Math.floor(
    (result.correct / (result.correct + result.false)) * 100
  );

  return (
    <div className=" bg-white w-full max-w-md p-4 min-h-screen">
      <h1 className=" font-bold text-2xl my-2">{`${strings?.result}: ${percentage}%`}</h1>
      {problems && problems.length > 1 && <ResultScore result={result} />}
      {problems && problems.length > 0 && (
        <div className="mt-4 flex flex-col space-y-2">
          {problems.map((p, i) => {
            return (
              <Link
                key={p.id}
                to={`/unit/${props.unitId}/${i}${demo ? "?demo=1" : ""}`}
                state={{ fromResult: true, unit: unitFromState }}>
                <ResultUnit key={p.id} problem={p} index={i + 1} />
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ResultUnit = ({
  problem,
  index
}: {
  problem: ProblemType;
  index: number;
}) => {
  return (
    <div className=" rounded p-2 border-solid border-2 border-gray-200 flex flex-row items-center space-x-2">
      <FontAwesomeIcon
        className={problem.userIsCorrect ? " text-green-500" : " text-red-500"}
        icon={problem.userIsCorrect ? faCheckCircle : faTimesCircle}
      />
      <p className="flex-1">{`Frage ${index}`}</p>
      <FontAwesomeIcon className="text-gray-600" icon={faChevronRight} />
    </div>
  );
};

const ResultScore = ({
  result
}: {
  result: { correct: number; false: number };
}) => {
  return (
    <div className=" rounded flex flex-row overflow-hidden">
      {result.correct > 0 && (
        <div
          className="bg-green-600 flex flex-col items-center px-4 py-4 text-center text-white"
          style={{ flex: result.correct / (result.correct + result.false) }}>
          {result.correct}
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>
      )}
      {result.false > 0 && (
        <div
          className="bg-red-600 flex flex-col items-center px-4 py-4 text-center text-white"
          style={{ flex: result.false / (result.correct + result.false) }}>
          {result.false}
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
      )}
    </div>
  );
};

const ResultPageWithToken = (props: ResultPageProps) => {
  const unit = (props.location?.state as any)?.unit;
  const demo = isDemo();
  return (
    <WhoAmIContainer allowGuest={demo && unit?.publicAccess}>
      <ResultPage {...props} />
    </WhoAmIContainer>
  );
};

export default ResultPageWithToken;
