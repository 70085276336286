import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import { get } from "../api";
import { WhoAmIContainer } from "../App";
import { UnitType } from "./quiz";
import { isDemo } from "../utils/is-demo";

const useUnit = (unitId: string, initialUnit?: UnitType) => {
  console.log(initialUnit);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [unit, setUnit] = useState<UnitType | null>(initialUnit ?? null);

  useEffect(() => {
    if (!unitId) {
      setIsLoading(false);
      setUnit(null);
      setError(null);
      return;
    }
    if (unit) {
      return;
    }
    get(`/unit/${unitId}`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status) {
          console.log(res.data);
          setUnit(res.data);
        } else {
          setError(res.error.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setError(err.message);
      });
  }, [unitId, unit]);

  return {
    isLoading,
    error,
    unit
  };
};

const Loading = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen">
      <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
    </div>
  );
};

const Error = ({ error }: { error: any }) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen">
      {error}
    </div>
  );
};

export interface UnitPageProps extends RouteComponentProps {
  unitId?: string;
  problemIndex?: string;
}
const UnitPage = (props: UnitPageProps) => {
  const { navigate, location } = props;
  const unitId = props.unitId;
  const problemIndex = props.problemIndex ?? 0;
  const demo = isDemo();

  const { unit, error } = useUnit(unitId ?? "", (location?.state as any)?.unit);

  if (unit && navigate) {
    const fromResult = (location?.state as any)?.fromResult ?? false;
    const options = {
      state: { unit, fromResult },
      replace: true
    };
    console.log(options);
    if (unit.resourceType === "interactive") {
      navigate(`/interactive/${unitId}${demo ? "?demo=1" : ""}`, options);
    } else {
      navigate(
        `/quiz/${unitId}/${problemIndex}${demo ? "?demo=1" : ""}`,
        options
      );
    }
  }

  if (error) {
    return <Error error={error} />;
  }

  return <Loading />;
};

const UnitPageWithToken = (props: UnitPageProps) => {
  const { unit, isLoading } = useUnit(props.unitId ?? "");
  const demoMode = isDemo();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <WhoAmIContainer allowGuest={demoMode && unit?.publicAccess}>
      <UnitPage {...props} />
    </WhoAmIContainer>
  );
};

export default UnitPageWithToken;
