import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { mutate } from "swr";
import { WhoAmIContainer } from "../App";

const LogoutPage = () => {
  useEffect(() => {
    window.localStorage.removeItem("token");
    mutate("/student/whoami");
  }, []);
  return null;
};

const LogoutPageWithWhoAmI = (props: RouteComponentProps) => {
  return (
    <WhoAmIContainer>
      <LogoutPage />
    </WhoAmIContainer>
  );
};

export default LogoutPageWithWhoAmI;
