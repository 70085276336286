import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Loading = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <FontAwesomeIcon icon={faPlus} className=" animate-spin " />
    </div>
  );
};

export default Loading;
