const strings = {
  wrong: "Falsch",
  correct: "Richtig",
  welcome: "Willkommen im Verkehrskundeunterricht",
  submit: "Registrieren",
  registered: "Registriert",
  thanksForRegistering: "Danke für Deine Registrierung!",
  step1: "Schritt 1",
  step2: "Schritt 2",
  someMaterials:
    "Bitte lade Dir die Youtube-App herunter. Anschliessend kannst Du Dir die 360°-Videos über die QR-Codes im Heft anschauen.",
  simplyScan:
    "Scanne ganz einfach die QR-Codes in Deinem Heft, um Zugang zu den interaktiven Inhalten zu erhalten.",
  emailAddress: "E-Mail-Adresse",
  enterEmailAddress:
    "Bitte gib Deine E-Mail-Adresse ein, um Dich für den Verkehrskundeunterricht zu registrieren. Wir benutzen die Email nur für Informationen im Zusammenhang mit dem VKU.",
  previousQuestion: "Vorangehende Frage",
  nextQuestion: "Nächste Frage",
  interactiveQuiz: "Erkennst Du die Gefahr?",
  interactiveInfo:
    "Erkenne die gefährlichen Situationen rechtzeitig! Spiele das Video ab und tippe auf den Bildschirm sobald die Gefahr erkennbar ist. Bist Du bereit?",
  viewInYoutube: "Ansicht in der Youtube-App",
  check: "Prüfen",
  tapToStart: "Zum starten auf den Bildschirm tippen",
  tapAnywhereWhenHazard:
    "Bei sich entwickelnder Gefahr auf den Bildschirm tippen",
  exit: "Schliessen",
  tryAgain: "Noch einmal",
  seeSolution: "Lösung ansehen",
  finish: "Fertig",
  result: "Ergebnis",
  return: "Zurück"
};

export type LocaleType = typeof strings;

export default strings;
