import React, { ReactNode } from "react";

const Layout: React.FC<{}> = ({ children }) => {
  return (
    <div className="w-full flex flex-col gradient-bg justify-start items-center min-h-screen">
      {children}
    </div>
  );
};

export const CenteredLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="gradient-bg w-full h-screen flex flex-col items-center justify-center">
      {children}
    </div>
  );
};

export const CenteredWhiteContainer = ({
  children
}: {
  children: ReactNode;
}) => {
  return (
    <div className="p-4 mx-2 max-w-lg bg-white rounded shadow ">{children}</div>
  );
};

export default Layout;
