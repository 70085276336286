import { isDemo } from "./utils/is-demo";

export const get = (path: string) => {
  const demo = isDemo();
  const token = demo ? "" : window.localStorage.getItem("token");
  return fetch(
    `${
      process.env.REACT_APP_API_URL || "http://localhost:3000"
    }/api/app${path}`,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`
          }
        : undefined
    }
  );
};

export const post = (path: string, body: any) => {
  const demo = isDemo();
  const token = demo ? "" : window.localStorage.getItem("token");
  return fetch(
    `${
      process.env.REACT_APP_API_URL || "http://localhost:3000"
    }/api/app${path}`,
    {
      method: "POST",
      body,
      headers: token
        ? {
            Authorization: `Bearer ${token}`
          }
        : undefined
    }
  );
};
