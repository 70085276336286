import React, { ReactNode, useState, useEffect, useContext } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSquare,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import Layout from "./layout";
import { post } from "../api";
import { WhoAmIContainer } from "../App";
import { LanguageContext } from "../locale";
import { isDemo } from "../utils/is-demo";

interface UnitViewProps {
  id: string;
  unitId: string;
  resourceType: "movie" | "quiz" | "interactive";
  movieURL?: string;
  is360Video?: boolean;
  images?: Array<string>;
  title: string;
  question?: string;
  answers?: Array<AnswerType>;
  userReplies?: Array<string>;
  replyDate?: Date;
  hasNext?: boolean;
  hasPrevious?: boolean;
  userIsCorrect?: boolean;
  onSelectAnswer?: (answer: string) => void;
  onClickCheck?: () => void;
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  onClickFinish?: (e?: any) => void;
  selectedImage?: string;
  setSelectedImage?: (image: string) => void;
  fromResult?: boolean;
  isLast?: boolean;
  onClickBackFromResult?: () => void;
}

const isSelected = (
  answer: AnswerType,
  userReplies: Array<string>
): boolean => {
  return userReplies.filter((u) => u === answer.id).length > 0;
};

const correctionIndicator = (answer: AnswerType): ReactNode => {
  if (answer.isCorrect)
    return <FontAwesomeIcon icon={faCheck} className="text-green-600" />;
  else return null;
};

export const ImagesContainer = ({
  images,
  selectedImage,
  setSelectedImage
}: {
  setSelectedImage: (image: string) => void;
  images: Array<string>;
  selectedImage?: string;
}) => {
  if (!images || images.length === 0) {
    return null;
  }
  return (
    <>
      {images && images.length > 0 && (
        <div className=" h-64">
          <img
            className=" w-full h-full object-contain"
            src={`${
              process.env.REACT_APP_MEDIA_URL ?? process.env.PUBLIC_URL
            }/uploads/${selectedImage || images[0]}`}
            alt=""
          />
        </div>
      )}
      {images && images.length > 1 && (
        <div className="p-2 relative my-2 overflow-x-scroll whitespace-no-wrap">
          {images.map((im, i) => {
            return (
              <div
                key={i}
                className={`w-16 h-16 inline-block bg-yellow shadow mb-2 mr-2 ${
                  selectedImage === im ? " border-blue-700 border-4" : ""
                }`}
                onClick={() => setSelectedImage(im)}>
                <img
                  src={`${
                    process.env.REACT_APP_MEDIA_URL ?? process.env.PUBLIC_URL
                  }/uploads/${im}`}
                  alt={im}
                  className=" w-full h-full object-contain"
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const QuizView = ({
  unitId,
  title,
  images,
  question,
  answers,
  replyDate,
  hasNext,
  hasPrevious,
  userReplies,
  userIsCorrect,
  onSelectAnswer,
  onClickPrevious,
  onClickNext,
  selectedImage,
  setSelectedImage = () => {},
  resourceType,
  movieURL,
  is360Video,
  onClickCheck,
  fromResult,
  isLast,
  onClickFinish
}: UnitViewProps) => {
  const { strings } = useContext(LanguageContext);
  const demo = isDemo();
  const hasAnswers =
    answers && answers.filter((a) => a.text.length > 0).length > 0;
  return (
    <div className=" bg-white w-full max-w-md p-4 min-h-screen">
      <h1 className=" font-bold text-2xl my-2">{title}</h1>
      {!movieURL && (
        <ImagesContainer
          images={images ?? []}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
        />
      )}
      {movieURL && !is360Video && (
        <div className="bg-black w-full h-48 mb-4">
          <iframe
            title="youtube"
            width="100%"
            height="100%"
            src={movieURL.replace("watch?v=", "embed/")}
            frameBorder="0"
            allowFullScreen={true}></iframe>
        </div>
      )}
      {movieURL && is360Video && (
        <div className="bg-black w-full h-48 mb-4 flex flex-col justify-center items-center">
          <a href={movieURL} target="__blank" className="btn-blue">
            {strings?.viewInYoutube}
          </a>
        </div>
      )}
      {replyDate && hasAnswers && (
        <div
          className={`rounded px-2 py-2 text-white text-center w-full mb-2 ${
            userIsCorrect ? "bg-green-600" : "bg-red-600"
          }`}>
          {userIsCorrect ? strings?.correct : strings?.wrong}
        </div>
      )}
      {question && <p className="py-2">{question}</p>}
      {hasAnswers && (
        <ul className="my-2">
          {answers?.map((ans, i) => {
            return (
              <li
                key={i}
                className="flex flex-row  items-center p-2 pr-4 rounded shadow mb-4 cursor-pointer"
                onClick={(e) => {
                  if (onSelectAnswer) {
                    onSelectAnswer(ans.id);
                  }
                }}>
                {isSelected(ans, userReplies ?? []) ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mr-2 bg-green-500 text-white p-1 rounded w-2"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faSquare}
                    className="mr-2 bg-gray-300 text-gray-100 p-1 rounded  w-2"
                  />
                )}

                <p className="flex-1 ml-2">{ans.text}</p>
                {replyDate && <div>{correctionIndicator(ans)}</div>}
              </li>
            );
          })}
        </ul>
      )}
      {!fromResult && !replyDate && question && hasAnswers && (
        <button
          onClick={onClickCheck}
          type="button"
          className="w-full bg-blue-500 text-white px-2 py-4 rounded">
          {strings?.check}
        </button>
      )}
      {!fromResult && replyDate && hasNext && (
        <button
          onClick={onClickNext}
          className="w-full block bg-green-400 text-white px-2 py-4 rounded text-center">
          {strings?.nextQuestion}
        </button>
      )}
      {!fromResult && hasPrevious && (
        <button className="my-2 text-blue-600" onClick={onClickPrevious}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          {strings?.previousQuestion}
        </button>
      )}
      {!fromResult && replyDate && !hasNext && (
        <button
          type="button"
          className="w-full bg-blue-500 text-white px-2 py-4 rounded"
          onClick={onClickFinish}>
          {strings?.finish}
        </button>
      )}
      {fromResult && (
        <Link
          className="my-2 text-blue-600"
          to={`/unit/${unitId}/result${demo ? "?demo=1" : ""}`}
          onClick={onClickFinish}>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
          {strings?.return}
        </Link>
      )}
    </div>
  );
};

export interface AnswerType {
  id: string;
  text: string;
  isCorrect: boolean;
  start?: string;
  finish?: string;
  score?: number;
}

export interface ProblemType {
  answers?: Array<AnswerType>;
  id: string;
  images?: Array<string>;
  is360Video?: boolean;
  question?: string;
  videoURL?: string;
  userReplies?: Array<string>;
  replyDate?: Date;
  userIsCorrect?: boolean;
}

export interface UnitType {
  unitId: string;
  unitName: string;
  resourceType: "movie" | "quiz" | "interactive";
  sharedVideoURL?: string;
  sharedImagesURL?: Array<string>;
  is360Video?: boolean;
  problems?: Array<ProblemType>;
  publicAccess?: boolean;
}

interface LocationState {
  unit: UnitType;
}
interface QuizPageProps extends RouteComponentProps {
  unitId?: string;
  problemIndex?: string;
}

const QuizPageWithUnit = (props: QuizPageProps) => {
  const unitFromState = (props.location?.state as LocationState).unit;
  const fromResult = (props.location?.state as any).fromResult ?? false;
  const demo = isDemo();

  const [replies, setReplies] = useState<Array<string>>([]);
  const [currentProblem, setCurrentProblem] = useState(
    parseInt(props.problemIndex ?? "") ?? 0
  );
  const initialImage = unitFromState.problems?.[currentProblem]?.images?.[0];
  const [selectedImage, setSelectedImage] = useState(initialImage);
  useEffect(() => {
    setSelectedImage(initialImage);
  }, [initialImage]);

  const numOfProblems = unitFromState?.problems?.length ?? 0;
  const currentProblemObj = unitFromState?.problems
    ? unitFromState?.problems[currentProblem]
    : null;

  if (!currentProblemObj) {
    return null;
  }

  console.log({ currentProblemObj });

  return (
    <Layout>
      <QuizView
        {...{
          ...currentProblemObj,
          unitId: props.unitId || "",
          fromResult,
          resourceType: unitFromState.resourceType,
          title: unitFromState.unitName,
          userReplies: currentProblemObj.replyDate
            ? currentProblemObj.userReplies
            : replies,
          movieType: "normal",
          movieURL: unitFromState.sharedVideoURL,
          selectedImage,
          is360Video: unitFromState.is360Video,
          setSelectedImage,
          hasNext: currentProblem < numOfProblems - 1,
          hasPrevious: currentProblem > 0,
          onClickCheck: () => {
            post(
              "/unit/reply",
              JSON.stringify({
                data: {
                  unitId: unitFromState.unitId,
                  problemId: currentProblemObj.id,
                  replies: replies.join(",")
                }
              })
            )
              .then((res) => res.json())
              .then((res) => {
                if (props.navigate) {
                  props.navigate(
                    `/unit/${unitFromState.unitId}/${currentProblem}${
                      demo ? "?demo=1" : ""
                    }`,
                    {
                      replace: true,
                      state: demo
                        ? {
                            unit: {
                              ...unitFromState,
                              problems: unitFromState.problems?.map(
                                (problem) => {
                                  if (problem.id === currentProblemObj.id) {
                                    return {
                                      ...problem,
                                      userIsCorrect: res.data.userIsCorrect,
                                      userReplies: res.data.userReplies,
                                      replyDate: res.data.replyDate
                                    };
                                  }
                                  return problem;
                                }
                              )
                            }
                          }
                        : undefined
                    }
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
          onSelectAnswer: (ans) => {
            if (replies.filter((r) => r === ans).length > 0) {
              setReplies(replies.filter((r) => r !== ans));
            } else {
              setReplies([...replies, ans]);
            }
          },
          onClickNext: () => {
            if (currentProblem < numOfProblems - 1) {
              setCurrentProblem((curr) => curr + 1);
            }
          },
          onClickPrevious: () => {
            if (currentProblem >= 0) {
              setCurrentProblem((curr) => curr - 1);
            }
          },
          onClickFinish: (e: Event) => {
            e.preventDefault();
            if (props.navigate) {
              props.navigate(
                `/unit/${unitFromState.unitId}/result${demo ? "?demo=1" : ""}`,
                {
                  state: demo
                    ? {
                        unit: unitFromState
                      }
                    : undefined
                }
              );
            }
          }
        }}
      />
    </Layout>
  );
};

const QuizPage = (props: QuizPageProps) => {
  if (
    (!props.location?.state ||
      !(props.location?.state as LocationState).unit) &&
    props.navigate
  ) {
    props.navigate(`/unit/${props.unitId}/${props.problemIndex}`, {
      replace: true
    });
    return null;
  }

  return <QuizPageWithUnit {...props} />;
};

const QuizPageWithToken = (props: QuizPageProps) => {
  const unit = (props.location?.state as any)?.unit;
  const demo = isDemo();
  return (
    <WhoAmIContainer allowGuest={demo && unit?.publicAccess}>
      <QuizPage {...props} />
    </WhoAmIContainer>
  );
};

export default QuizPageWithToken;

/**
 const sampleQuiz: () => UnitType | null = () => {
  if (process.env.NODE_ENV === "production") return null;
  return {
    unitId: "",
    resourceType: "quiz",
    problems: [
      {
        id: "d1",
        resourceType: "quiz",
        images: [
          "https://auto.itheorie.ch/images/autotheory/kr_000.jpg",
          "https://auto.itheorie.ch/images/autotheory/ctg_img_11.jpg"
        ],
        title: "Question #1",
        question:
          "Beachten Sie, dass Sie das Fahrzeug in jeder Situation beherrschen und Ihrer Vorsichtspflicht nachkommen müssen.",
        answers: [
          {
            id: "1",
            text:
              "Innerorts beträgt die allgemeine Höchstgeschwindigkeit 50 km/h.",
            isCorrect: true
          },
          {
            id: "2",
            text:
              "Innerorts beträgt die allgemeine Höchstgeschwindigkeit 50 km/h.",
            isCorrect: false
          },
          {
            id: "3",
            text:
              "Innerorts beträgt die allgemeine Höchstgeschwindigkeit 50 km/h.",
            isCorrect: true
          }
        ],
        userReplies: [],
        replyDate: new Date(),
        userIsCorrect: true
      },
      {
        id: "d2",
        resourceType: "quiz",
        images: ["https://itheorie.ch/images/ch_truck_premium_promo.png"],
        title: "Question #2",
        question:
          "Erweitertes Übungsfragenset Kategorie C. Mehr als 350 Zusatzfragen zum offiziellen Fragenset der ASTAG.",
        answers: [
          {
            id: "1",
            text:
              "Innerorts beträgt die allgemeine Höchstgeschwindigkeit 50 km/h.",
            isCorrect: true
          },
          {
            id: "2",
            text:
              "Innerorts beträgt die allgemeine Höchstgeschwindigkeit 50 km/h.",
            isCorrect: false
          },
          {
            id: "3",
            text:
              "Innerorts beträgt die allgemeine Höchstgeschwindigkeit 50 km/h.",
            isCorrect: true
          }
        ],
        userReplies: [],
        replyDate: new Date(),
        userIsCorrect: true
      }
    ]
  };
};
 */
