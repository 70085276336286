import React, { useEffect, useState } from "react";
import useSwr from "swr";
import { Router } from "@reach/router";
import QuizPage from "./components/quiz";
import RegisterPage from "./components/register";
import InteractivePage from "./components/interactive";
import UnitPage from "./components/unit";
import { get } from "./api";
import { CenteredLayout, CenteredWhiteContainer } from "./components/layout";
import Loading from "./components/loading";
import { LanguageContext } from "./locale";
import deStrings from "./locale/de";
import enStrings from "./locale/en";
import ResultPage from "./components/result";
import LogoutPage from "./components/logout";

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState("de");
  const [strings, setStrings] = useState(deStrings);

  useEffect(() => {
    if (selectedLanguage === "en") {
      setStrings(enStrings);
    } else {
      setStrings(deStrings);
    }
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider
      value={{
        language: selectedLanguage,
        strings,
        setLanguage: setSelectedLanguage
      }}>
      <Router>
        <UnitPage path="/unit/:unitId" />
        <UnitPage path="/unit/:unitId/:problemIndex" />
        <QuizPage path="/quiz/:unitId/:problemIndex" />
        <InteractivePage path="/interactive/:unitId" />
        <RegisterPage path="/register/:registrationId" />
        <ResultPage path="/unit/:unitId/result" />
        <LogoutPage path="/logout" />
      </Router>
    </LanguageContext.Provider>
  );
}

export const WhoAmIContainer = ({
  children,
  guestComponent,
  allowGuest
}: {
  children: any;
  guestComponent?: any;
  allowGuest?: boolean;
}) => {
  const { data, error } = useSwr("/student/whoami", (path) =>
    get(path).then((r) => r.json())
  );

  const isLoading = !data && !error;

  if (isLoading) {
    return (
      <CenteredLayout>
        <Loading />
      </CenteredLayout>
    );
  }

  if (!allowGuest && (error || (data && !data.status))) {
    if (guestComponent) {
      return guestComponent;
    } else {
      return (
        <CenteredLayout>
          <CenteredWhiteContainer>
            Womöglich warst du bei der Registrierung mit deinem Gerät im
            privaten Surfmodus. Bitte schalte den privaten Surfmodus aus und
            scanne den ersten QR Code in Heft 1 noch einmal.
          </CenteredWhiteContainer>
        </CenteredLayout>
      );
    }
  }

  return children;
};

export default App;
