import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { CenteredLayout, CenteredWhiteContainer } from "./layout";
import { post, get } from "../api";
import Loading from "./loading";
import { LanguageContext } from "../locale";

interface RegisterViewProps {
  onSubmit: () => void;
  email: string;
  setEmail: (email: string) => void;
  error?: string;
}
const RegisterView = ({
  onSubmit,
  email,
  setEmail,
  error
}: RegisterViewProps) => {
  const { strings } = useContext(LanguageContext);
  return (
    <CenteredLayout>
      <CenteredWhiteContainer>
        <div className="w-full flex flex-row justify-center">
          <img
            src="/logo.png"
            alt="iTheorie Logo"
            className=" rounded-full w-16 h-16"
          />
        </div>
        <h1 className=" text-2xl text-center font-bold mt-4 mb-24 break-word">
          {strings?.welcome}
        </h1>
        <p>{strings?.enterEmailAddress}</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="flex flex-col">
          <div className="my-4 flex flex-col">
            <label htmlFor="email" className=" text-sm font-bold mb-2">
              {strings?.emailAddress}
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="cool-input"
              type="email"
              id="email"
              placeholder="aaaa@itheorie.ch"
            />
          </div>
          <button type="submit" className="btn-blue">
            {strings?.submit}
          </button>
        </form>
      </CenteredWhiteContainer>
    </CenteredLayout>
  );
};

const RegisterSuccessView = () => {
  const { strings } = useContext(LanguageContext);
  return (
    <CenteredLayout>
      <CenteredWhiteContainer>
        <h1 className=" text-3xl font-bold mt-4">{strings?.registered}</h1>
        <p className="mb-4">{strings?.thanksForRegistering}</p>
        <div className="border-2 border-gray-100 rounded-lg shadow p-4">
          <h2 className="font-bold text-2xl text-blue-500">{strings?.step1}</h2>
          <p>{strings?.someMaterials}</p>
          <div className="flex flex-row space-x-2 mt-2">
            <a href="https://apps.apple.com/us/app/youtube-watch-listen-stream/id544007664">
              <img src="/appstore.png" alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.google.android.youtube&hl=en">
              <img src="/playstore.png" alt="" />
            </a>
          </div>
        </div>
        <div className="border-2 border-gray-100 rounded-lg shadow p-4 mt-4">
          <h2 className="font-bold text-2xl text-blue-500">{strings?.step2}</h2>
          <p>{strings?.simplyScan}</p>
        </div>
      </CenteredWhiteContainer>
    </CenteredLayout>
  );
};

interface RegisterPageProps extends RouteComponentProps {
  registrationId?: string;
}
const RegisterPage = (props: RegisterPageProps) => {
  const registrationId = props.registrationId || "";
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const { strings } = useContext(LanguageContext);

  useEffect(() => {
    get("/student/whoami")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.status) {
          setIsLoading(false);
          setIsSuccess(true);
        }
      })
      .catch((err) => setIsLoading(false));
  }, []);

  function onSubmit() {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("code", registrationId);

    post("/student/register", formData)
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.status) {
          const token = response.data.token;
          window.localStorage.setItem("token", token);
          setIsSuccess(true);
        } else {
          setError(response.error);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Booklet has been registered to different email address");
        setIsLoading(false);
      });
  }

  if (!registrationId) {
    return (
      <CenteredLayout>
        <CenteredWhiteContainer>
          <p className=" text-center">Not found</p>
        </CenteredWhiteContainer>
      </CenteredLayout>
    );
  }

  if (isLoading) {
    return (
      <CenteredLayout>
        <Loading />
      </CenteredLayout>
    );
  }

  if (error) {
    return (
      <CenteredLayout>
        <CenteredWhiteContainer>
          <div className="flex flex-col items-center">
            <p className=" text-center">{error}</p>
            <button onClick={() => setError("")} className="btn-blue mt-2">
              {strings?.tryAgain}
            </button>
          </div>
        </CenteredWhiteContainer>
      </CenteredLayout>
    );
  }

  if (isSuccess) {
    return <RegisterSuccessView />;
  }

  return <RegisterView email={email} setEmail={setEmail} onSubmit={onSubmit} />;
};

export default RegisterPage;
